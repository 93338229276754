import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
// Material UI Kit
import GridContainer from "../components/Grid/GridContainer"
import Card from "../components/Card/Card"
import CardHeader from "../components/Card/CardHeader"
import GridItem from "../components/Grid/GridItem"
// Styles
import withStyles from "@material-ui/core/styles/withStyles"
import javascriptStyles from "../assets/jss/material-kit-pro-react/views/componentsSections/javascriptStyles"

const style = {
  cardBorder: {
    marginBottom: "30px",
    padding: "20px",
  },
  titleCenter: {
    textAlign: "center",
    padding: "20px",
  },
  message: {
    textAlign: "center",
    padding: "50px",
    color: "#626f78",
  },
  shaka: {
    height: "75px",
    marginTop: "40px",
  },
}

const ThankYouPage = (props) => {
  const { classes } = props
  return (
    <Layout>
      <SEO title="Contact Success" />
      <GridContainer
        spacing={0}
        direction="column"
        alignItems="center"
        justify="center"
        style={{ padding: "1.5rem 16px" }}
      >
        <Card style={style.cardBorder}>
          <CardHeader
            plain
            noShadow
            color="primary"
            className={`${classes.textCenter} ${classes.cardLoginHeader}`}
          >
            <h3 className={classes.cardTitleWhite}>
              Thank you for submitting an RSVP for this exclusive event!
            </h3>
          </CardHeader>
          <GridContainer>
            <GridItem className={classes.mlAuto} style={style.message}>
              <h3>
                You will be contacted if you are one of the lucky winners of a
                VIP Ticket to attend the event. We will be communicating by
                email so be sure to keep an eye out for messages from us
                including news, updates and ways to experience the boat show
                virtually if you're unable to attend!
              </h3>
            </GridItem>
          </GridContainer>
        </Card>
      </GridContainer>
    </Layout>
  )
}
export default withStyles(javascriptStyles)(ThankYouPage)
